<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="['commodity_group.name', 'unit_make.name', 'name']"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields=selectFields
    >
        <template #columns>
            <Column field="commodity_group.name" :header="$t('navigation.commoditygroups', false)"></Column>
            <Column field="unit_make.name" :header="$t('navigation.makes', false)"></Column>
            <Column field="name" :header="$t('overview.name')"></Column>
            <Column field="code" :header="$t('overview.code')"></Column>

            <Column field="unit_measurement.length" :header="$t('form.length')">
                <template #body="slotProps">
                  {{setMeasurement(slotProps.data.unit_measurement, 'length')}}
                </template>
            </Column>
            <Column field="unit_measurement.width" :header="$t('form.width')">
                <template #body="slotProps">
                  {{setMeasurement(slotProps.data.unit_measurement, 'width')}}
                </template>
            </Column>
            <Column field="unit_measurement.height" :header="$t('form.height')">
                <template #body="slotProps">
                  {{setMeasurement(slotProps.data.unit_measurement, 'height')}}
                </template>
            </Column>
            <Column field="unit_measurement.weight" :header="$t('form.weight')">
                <template #body="slotProps">
                  {{setMeasurement(slotProps.data.unit_measurement, 'weight')}}
                </template>
            </Column>
        </template>

        <template #form>
            <ModelForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import ModelForm from '@/components/model/ModelForm.vue';
import modelService from '@/services/ModelService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        ModelForm
    },
    data() {
        return {
            item: {},
            itemService: modelService,
            itemFormRef: null,
            selectFields: [
                'id', 'name', 'code', 
                'commodity_group_id',
                {'commodityGroup': ['id', 'name']},
                'unit_make_id',
                {'unitMake': ['id', 'name']},
                'unit_measurement_id',
                {'unitMeasurement': ['length','width','height','weight']}
            ]
        }
    },
	methods: {
        setMeasurement(data, field){
            return (data && data[field]) ? (data[field] + (field == 'weight' ? ' kg' : ' cm')) : '';
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>